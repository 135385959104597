/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { OnlyNumbersMask } from 'react-masked-text/src/masks/only-numbers.mask';
import useStyles from './styles';
import { PageHeader } from '../../components/PageHeader';
import FindAddress from './FindAddress';
import ConfirmAddress from './ConfirmAddress';
import { findCEP } from '../../utils/findCEP';
import { orderSetAddress } from '../../store/modules/order/actions';
import { addressesModal } from '../../store/modules/addresses/actions';

function Addresses() {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [findAddressModal, setFindAddressModal] = useState(false);
  const [address, setAddress] = useState(null);

  const { company, kms, districts } = useSelector(state => state.data);
  const { modalAddresses, addresses } = useSelector(state => state.addresses);
  const { order } = useSelector(state => state.order);

  const getLocation = async ({ lat, lng }) => {
    try {
      const key = process.env.REACT_APP_GOOLE_MAPS_KEY;

      const data = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${key}`,
        { method: 'GET' }
      ).then(res => res.json());

      const result = data.results[0];

      const onlyNumbers = new OnlyNumbersMask();
      const onlyCep = onlyNumbers.getRawValue(
        result.address_components[6]
          ? result.address_components[6].long_name
          : '00000000'
      );

      const value = onlyCep.length === 8 ? onlyCep : '00000000';

      const cep = await findCEP(value);

      const { coduf, codcidade } = cep;

      setAddress({
        name: '',
        zip_code: value,
        public_place: result.address_components[1].long_name,
        number: result.address_components[0].long_name,
        complement: '',
        state: result.address_components[4].short_name,
        code_state: coduf,
        city: result.address_components[3].short_name,
        code_city: codcidade,
        neighborhood: result.address_components[2].long_name,
        comments: '',
        latitude: result.geometry.location.lat,
        longitude: result.geometry.location.lng,
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleClose = () => {
    dispatch(addressesModal());
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          getLocation({ lat: latitude, lng: longitude });
        },
        error => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              enqueueSnackbar(
                'Usuário rejeitou a solicitação de Geolocalização.',
                { variant: 'error' }
              );
              break;
            case error.POSITION_UNAVAILABLE:
              enqueueSnackbar(
                'As informações de localização não estão disponíveis.',
                { variant: 'error' }
              );
              break;
            case error.TIMEOUT:
              enqueueSnackbar(
                'A solicitação para obter a localização do usuário expirou.',
                { variant: 'error' }
              );
              break;
            case error.UNKNOWN_ERROR:
              enqueueSnackbar('Ocorreu um erro desconhecido.', {
                variant: 'error',
              });
              break;
            default:
              enqueueSnackbar('Ocorreu um erro desconhecido.', {
                variant: 'error',
              });
          }
        },
        { enableHighAccuracy: true, timeout: 30000, maximumAge: 0 }
      );
    } else {
      enqueueSnackbar('A geolocalização não é compatível com este navegador.', {
        variant: 'error',
      });
    }
  };

  const handleSelectAddress = async selectedAddress => {
    if (order && order.address && order.address.key === selectedAddress.key)
      return;

    if (company.verificarbairro === 'S') {
      const districtName = selectedAddress.neighborhood
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toUpperCase();

      const district = districts.find(f => f.name === districtName);

      if (!district) {
        enqueueSnackbar('Entrega indisponível para este local', {
          variant: 'error',
        });
        return;
      }

      const { delivery = 0 } = district;
      dispatch(orderSetAddress({ ...selectedAddress, delivery, kms: 0 }));
      dispatch(addressesModal());
      return;
    }

    const directionsService = new google.maps.DirectionsService();

    const latDestination = selectedAddress.latitude;
    const lngDestination = selectedAddress.longitude;
    const latOrigin = company.latitude;
    const lngOrigin = company.longitude;

    directionsService.route(
      {
        origin: { lat: latOrigin, lng: lngOrigin },
        destination: { lat: latDestination, lng: lngDestination },
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        try {
          if (status !== google.maps.DirectionsStatus.OK) {
            throw new Error('Google retornou status diferente de OK');
          }

          const { legs } = result.routes[0];
          const { value } = legs[0].distance;
          const km = Math.round(value / 1000);
          const kmFinding = kms.find(k => k.name === `KM${km}`);

          if (!kmFinding)
            throw new Error('Entrega indisponível para este local');

          const { delivery = 0 } = kmFinding;

          dispatch(orderSetAddress({ ...selectedAddress, delivery, kms: km }));

          dispatch(addressesModal());
        } catch (error) {
          const { message = '' } = error;
          enqueueSnackbar(
            message || 'Houve uma falha ao tentar calcular a taxa de entrega.',
            {
              variant: 'error',
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    setAddress(null);
  }, [addresses]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={modalAddresses}
        className={classes.root}
      >
        <PageHeader handleClose={handleClose} title="meus endereços" />
        <div className={classes.content}>
          <div className={classes.buttons}>
            <button
              type="button"
              className={classes.button}
              onClick={() => setFindAddressModal(true)}
            >
              <EditIcon />
              <div className={classes.addressButtonInfo}>
                <span>Adicionar</span>
                <span>Digitando CEP, Bairro, Rua e Número</span>
              </div>
            </button>

            <button
              type="button"
              className={classes.button}
              onClick={handleGetLocation}
            >
              <MyLocationIcon />
              <div className={classes.addressButtonInfo}>
                <span>Adicionar</span>
                <span>Usando minha localização atual</span>
              </div>
            </button>
          </div>

          <div className={classes.addresses}>
            {addresses.map(item => (
              <button
                type="button"
                key={JSON.stringify(item)}
                className={clsx(classes.addressButton, {
                  [classes.selected]:
                    order && order.address && order.address.key === item.key,
                })}
                onClick={() => handleSelectAddress(item)}
              >
                <LocationOnIcon />
                <div className={classes.addressButtonInfo}>
                  <span>{item.name}</span>
                  <span>
                    {` ${item.zip_code}`}
                    {` ${item.public_place}`}
                    {` ${item.number},`}
                    {` ${item.state}`}
                    {` ${item.city}`}
                    {` ${item.neighborhood}`}
                  </span>
                </div>
              </button>
            ))}
          </div>
        </div>
      </Dialog>

      <FindAddress
        findAddressModal={findAddressModal}
        setFindAddressModal={setFindAddressModal}
      />

      {!!address && (
        <ConfirmAddress address={address} setAddress={setAddress} />
      )}
    </>
  );
}

export default Addresses;
